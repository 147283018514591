header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}
h3 {
    font-size: 35px;
}
h1 {
    font-size: 55px;
}
h4 {
    font-size: 20px;
}
/********** CTA **********/
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/********** Header Social **********/
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* .card {
    display: block;
    width: 30vw;
    min-height: 90px;
    border: 3px solid #555BFF;
    padding: 15px;
    margin: 0 auto;
    box-shadow: 10px -10px 0 -3px white, 10px -10px #1FC11B,
        20px -20px 0 -3px white, 20px -20px #FFD913,
        30px -30px 0 -3px white, 30px -30px #FF9C55,
        40px -40px 0 -3px white, 40px -40px #FF5555;
    transition: box-shadow 1s, top 1s, left 1s;
    position: relative;
    top: 0;
    left: 0;
    cursor: pointer;
}

.card:hover {
    top: -40px;
    left: 40px;
    box-shadow: 0 0 0 -3px white, 0 0 0 0 #1FC11B,
        0 0 0 -3px white, 0 0 0 0 #FFD913,
        0 0 0 -3px white, 0 0 0 0 #FF9C55,
        0 0 0 -3px white, 0 0 0 0 #FF5555;
} */


/********** RingRing **********/
.RingRing {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: .5rem .5rem .5rem .5rem;
}

/********** Scroll Down **********/
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;

}


/************************ Media Quaries (Medium Devices) ************************/
@media screen and (max-width: 1024px) {
    header {
        height: 100vh;
    }

    .RingRing {
        background: linear-gradient(var(--color-primary), transparent);
        width: 22rem;
        height: 22rem;
        position: absolute;
        left: calc(50% - 11rem);
        margin-top: 4rem;
        object-fit: cover;
        border-radius: 50%;
        overflow: hidden;
        padding: .5rem .5rem .5rem .5rem;
    }

}

/************************ Media Quaries (Small Devices) ************************/
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .header__socials,
    .scroll__down {
        display: none;
    }

}
#portfolio {
  margin-top: 10rem;
}

h5 {
  font-size: 20px;
}

/* .portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
} */

.portfolio__container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: 400px;

}

.portfolio__item {
  background: var(--color-background-variant);
  box-shadow: 1px 2px 3px 4px rgba(12, 12, 12, 0.2);
  padding: 1.3rem;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 15px;
  border: 1px solid transparent;
  transition: var(--transition);

}


.portfolio__item:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
  color: black;

}

.portfolio__item-image {
  border-radius: 2px;
  overflow: hidden;
  width: 100%;
  height: 10rem;
  border-color: #92a8d1;
  border-style: ridge;
  border-width: 5px;
}

/* .portfolio__item h3 {
  margin: 1.2rem 0 2rem;
} */

.portfolio__item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

h5 {
  margin-bottom: .5rem;
}

h3 {
  margin-bottom: .5rem;
}

.btn {
  margin-left: 20px;
  margin-right: 20px;
}

@media screen and (max-width: 1024px) {


  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
   
  }
  .portfolio__item{
    height: 20rem;
    min-height: 2rem;
    overflow: hidden;
  }
  .portfolio__item-image{
    min-height: 5rem;
    overflow: hidden;
  }
  .btn {
    margin: 0 auto;
    margin-top: 10px;
    margin-right: 4px;
    margin-left: 4px;
    align-items: center;
    text-align: center;
  }

}

@media screen and (max-width: 600px) {

  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
   
  }
  .portfolio__item{
    height: 20rem;
    min-height: 2rem;
   
  }
  .portfolio__item-image{
    min-height: 5rem;
   
  }
  .btn {
    margin: 0 auto;
    margin-top: 10px;
    margin-right: 4px;
    margin-left: 4px;
    align-items: center;
    text-align: center;
  }
}
.services__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
}

.services__item {
    background: var(--color-background-variant2);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    
}
.services__icon {
    font-size: 3rem;
    margin: 0 auto;
}

.services__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.services__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.services__item h3 {
    margin: 1.2rem 0 2rem;
}

ul.responsibilities {
    list-style-type: circle;
}


@media screen and (max-width: 1024px) {

    .services__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }

}

@media screen and (max-width: 600px) {

    .services__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

}

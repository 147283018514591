footer {
    background: var(--color-primary);
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 2rem;
}

footer a {
    color: var(--color-background);
}

.footer__logo {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: inline-block;
}

.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;
}

.footer__socials {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;
}

.footer__socials a {
    background: var(--color-background);
    color: var(--color-white);
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    border: 1px solid transparent;
}

.footer__social a:hover {
    background: transparent;
    color: var(--color-background);
    border-color: var(--color-background);
}

.footer__copyright {
    margin-bottom: 4rem;
    color: var(--color-background);
}



/* @media screen and (max-width: 1024px) {

    .container.contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

} */

@media screen and (max-width: 600px) {

  .permalinks {
      flex-direction: column;
      gap: 1.5rem;
  }
  .footer__socials {
      margin-bottom: 2.6rem;
  }

}
@media only screen and (max-width: 995px),
screen and (max-height: 700px){

    footer {
        margin-top: 250px;
    }
  
  }
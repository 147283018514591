@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap');


.skills {
    width: 55vw;
    margin: 0 auto;
}

.section__title {
    font-size: 3rem;
    margin-bottom: .02rem;
}

.skills__container {
    row-gap: 0;
}

.skills__content {
    margin-top: 80px;
}

.skills__header {
    display: flex;
    align-items: center;
    margin-bottom: 2.5rem;
    cursor: pointer;
}

.skills__icon,
.skills__arrow {
    font-size: 2rem;
    color: hsl(250, 69%, 61%);
    background-color: transparent;
    cursor: pointer;
}

.skills__icon {
    margin-right: .75rem;
}

.skills__titles {
    font-size: 1.125rem;
}

.skills__subtitle {
    font-size: .813rem;
    color: hsl(250, 8%, 65%);
}

.skills__arrow {
    margin-left: auto;
    transition: .4s;
}

.rotate {
    transform: rotate(-180deg);
}

.skills__list {
    row-gap: 1.5rem;
    padding-left: 2.7rem;
}

.skills__titless {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

.skills__name {
    font-size: .938rem;
    font-weight: 500;
}

.skills__bar,
.skills_percentage {
    height: 5px;
    border-radius: .25rem;
}

.skills__bar {
    background-color: hsl(250, 92%, 85%);
    ;
}

.skills_percentage {
    display: block;
    background-color: hsl(250, 69%, 61%);
}

/*** skill bar percentages ***/
.skills__html {
    width: 90%;
}

.skills__css {
    width: 90%;
}

.skills__js {
    width: 85%;
}

.skills__react {
    width: 80%;
}

.skills__php {
    width: 55%;
}

.skills__node {
    width: 65%;
}

.skills__python {
    width: 25%;
}

.skills__sql {
    width: 55%;
}

.skills__photoshop {
    width: 85%;
}

.skills__xd {
    width: 90%;
}

.skills__illustrator {
    width: 80%;
}

.skills__indesign {
    width: 55%;
}

.skills__close .skills__list {
    height: 0;
    overflow: hidden;
}

.skills__open .skills__list {
    height: max-content;
    margin-bottom: 2.5rem;
}

/* .skills__open .skills__arrow {
    transform: rotate(-180deg);
} */






/************************ Media Quaries (Medium Devices) ************************/
@media screen and (max-width: 1024px) {
    .skills__container container grid {
        margin-bottom: 50px;
    }

    .skills__content {
        margin-top: 20px;
    }

    /* #skills {
        margin-top: 300px;
    } */

    .skills {
        width: 80vw;
    }

}

/************************ Media Quaries (Small Devices) ************************/
@media screen and (max-width: 600px) {

    .skills__container container grid {
        margin-bottom: 50px;
    }

    .skills__content {
        margin-top: 20px;
    }

    #skills {
        margin-top: 250px;
    }

    .skills {
        width: 100vw;
    }

}

@media only screen and (min-width: 600px) {
    .skills__content {
        margin-top: 20px;
    }

    #skills {
        margin-top: 350px;
    }

    .skills {
        width: 100vw;
    }

}

@media only screen and (max-width: 995px),
screen and (max-height: 700px) {
    .skills__content {
        margin-top: 20px;
    }

    #skills {
        margin-top: 550px;
    }

    .skills {
        width: 100vw;
    }

}